import React, { useEffect, useState } from 'react';
import RTBG from '../images/Refining-Technique-bg.jpg';
import './about-us.scss';
import FeedbackForm from '../components/FeedbackForm/FeedbackForm';
import Page from '../components/layout/Page';
import Banner from '../components/Banner/Banner';

import './about-us.scss';
import Recognitions from '../components/index-page-sections/Recognitions';
import ImgBgContainer from '../components/ImgBgContainer';

const Quotes = [
  'We want to be the best, most accurate and reliable gold refiner.',
  'Your loyalty is our greatest asset. We serve our customers with utmost honesty, simplicity and loyalty.',
];

const aboutUsText =
  'Gold is not just another precious metal. Apart from its monetary worth, it reflects a sense of tradition and holds a deep ' +
  'emotional appeal. We totally understand the value that you associate gold and silver with. And that took us to the arena of refining gold and silver and of establishing ' +
  'safe routes to bring them to you. When Bright gold was founded in 2020, we envisioned it to be an all-encompassing bouquet of services related to gold. Today, our operations' +
  'cover refining, manufacturing, trading, supply, and recycling of gold and silver.';

const ceoMessage =
  '❝These are testing times of our resilience. But by sustainably adapting and keeping our focus unaltered, progress would be a breeze to attain. Because together, we definitely can! ❞';

const AboutUs = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setSlideIndex((index) => (index === Quotes.length - 1 ? 0 : index + 1));
    }, 3000);
  }, []);

  return (
    <Page name="About Us" className="about-us">
      <Banner bgImg="/images/gold-bars-stack.jpg" title="About Us"
      productText="Across the Globe people procure gold for many reason as Gold and sterling silver are precious metals, usually people get influence by national socio-cultural elements, circumstances of local market and macro-economic forces. Bright Gold is established in the year of 2020. We are the organization to refine and recycle precious metals. As a refiner and manufacturer, we are trader, supplier, and service provider as well. We offer abundant services such as counting salvaging, gold and silver processing. In addition Bright Gold holds NABL and BIS certifications." 
      />
      <Recognitions/>
      <section className="ribbon-blue-bg ">
        <div className="container text-light p-5">
          <h2 className="section-title gold-gradient-text">WHAT WE DO?</h2>
          <p className="mt-5 line-001">
            It is our privilege to bring you the finest gold from the major
            mining corporations across the world. Our sources range from the
            African peninsular regions like Tanzania and Uganda, to the South
            American heartlands like Peru and Bolivia. We import gold dore bars
            from some of the world’s largest mining companies, conforming to the
            DGFT standards set by the Govt. of India. We add to the global
            precious metals market, offering world class services tailored for
            each of the primary, secondary and tertiary sectors. Our clientele
            now spans from mining companies and jewelers to industrial
            corporations, commercial banks and governments.
          </p>
          <p>
            At present we are a full-fledged corporation covering Precious
            Metals Refining services such as
          </p>
          <ul className='ul-001'>
            <li>Gold Refining</li>
            <li>Spent X-Ray and Hypo-Fixer</li>
            <li>Solution Silver Recycling</li>
            <li>
              Gold Plating Electronic Components using the latest technology
            </li>
          </ul>
        </div>
      </section>

      <ImgBgContainer
        className="p-4"
      >
        <div className="">
          <div className="container">
            <div className='row  pad-001'>
              <div className='col-md-4'>
                <div className=' w-001 border-001'>
                   <div className='row m-0'>
                    <div className='col-8 color-001 d-flex justify-content-center align-items-center'>
                        <p className='gold-gradient-text p-001'>Our Mission</p>
                    </div>
                    <div className='col-4 color-002 p-0'>
                      <img src='../abt1.png' className='img-1'/>
                    </div>
                   </div>
                   </div>
              </div>
              <div className='col-md-8 d-flex justify-content-center align-items-center'>
                <p className='line-002 text-center'>At Bright Gold, we are the most precise and reliable Gold Refiner by continuously achieving operational excellence and delivering value - we want to be your Gold Refiner of preference.</p>
              </div>
            </div>

            <div className='row  pad-001'>
              <div className='col-md-8 d-flex justify-content-center align-items-center'>
                <p className='line-002 text-center'>With Bright Gold, we assured all our services would be certainly honest, reliable and quick for your precious metals. Our devotion is our greatest asset. Our vision is to serve our potential buyers with honesty, simplicity and loyalty. </p>
              </div>
              <div className='col-md-4'>
                <div className=' w-001 border-001'>
                   <div className='row m-0'>
                    <div className='col-8 color-001 d-flex justify-content-center align-items-center'>
                        <p className='gold-gradient-text p-001'>OUR VISION</p>
                    </div>
                    <div className='col-4 color-002 p-0'>
                      <img src='../abt2.png' className='img-1'/>
                    </div>
                   </div>
                   </div>
              </div>
            </div>
          </div>
        </div>
      </ImgBgContainer>

      <section className="ceo-message p-5 overflow-auto">
        <div className="container border-gold p-5">
          <div className="row">
            <div className="col-md-5 d-none d-md-block">
              <img src="/images/CEO.png" className="ceo-img" />
            </div>

            <div className="col-md-7">
              <h3 className="message-title">
                We want to be the best, most accurate and reliable gold refiner.
              </h3>

              <div className="d-md-none">
                <img src="/images/CEO.png" className="ceo-img-sm mt-5 w-100" />
              </div>
              <div className="quote-banner">
                <blockquote className="message">
                  ❝These are testing times of our resilience. But by sustainably
                  adapting and keeping our focus unaltered, progress would be a
                  breeze to attain. Because together, we definitely can! ❞
                </blockquote>
                <div className="ceo-name fw-bold">Mr. Ashish Gupta ( CEO )</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};

export default AboutUs;
